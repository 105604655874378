import { inject, Injectable, signal } from '@angular/core';
import { GLOBAL_CONFIG } from '@nats/tokens';
import { CookieService } from 'ngx-cookie-service';

const COOKIE_BANNER_KEY = 'cookiesAccepted';

@Injectable({ providedIn: 'root' })
export class CookieBannerService {
	private siteData = inject(GLOBAL_CONFIG);
	private cookieService = inject(CookieService);

	private cookiesRequired = this.siteData.theme.settings.acceptcookies_required === 'enabled';
	private isApproved = () => this.cookieService.get(COOKIE_BANNER_KEY) === 'true';

	readonly showModal = signal(this.cookiesRequired && !this.isApproved());

	// if show is false, then cookies are accepted
	// private readonly cookiesAreAccepted = computed(() => !this.showModal());

	acceptCookies() {
		// expires in 1 year
		this.cookieService.set(COOKIE_BANNER_KEY, 'true', 365);
		this.showModal.set(false);
	}
}
